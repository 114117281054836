import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "container nav-list" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, "英荔 AI 创造乐园 互动项目", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (nav, index) => {
          return (_openBlock(), _createElementBlock("a", {
            key: index,
            href: nav.href
          }, [
            _createElementVNode("img", {
              src: nav.cover,
              alt: ""
            }, null, 8, _hoisted_5)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}